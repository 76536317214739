.teaser-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.teaser-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.teaser-item {
    position: relative;
    overflow: hidden;
}

.teaser-image {
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
    object-fit: cover;
    display: block;
    transition: transform 0.3s ease;
}

.teaser-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    color: white;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
}

@media (max-width: 1200px) {
    .teaser-container {
        padding: 2.5rem;
    }
}

@media (max-width: 768px) {
    .teaser-wrapper {
        gap: 15px;
    }
}

@media (max-width: 480px) {
    .teaser-container {
        padding: 2rem;
    }

    .teaser-item {
        border-radius: 8px;
    }
}