.project-article {
    margin-bottom: 1.5rem;
}

.technology {
    padding: 3px 5px;
    margin: 4px 4px;
    background-color: #f5f5f5;
    border-radius: 5px;
}

.technology-flex {
    flex-wrap: wrap;
    flex-direction: row;
}

.project-article .col a {
    text-decoration: none;
}