.experience-grid-padding {
    padding: 2rem;
    margin: 0 auto;
    max-width: 1200px;
}

.project-container {
    padding: 2rem;
    margin: 0 auto;
    max-width: 1200px;
}

@media (max-width: 1200px) {
    .experience-grid-padding {
        padding: 2.5rem;
    }
}