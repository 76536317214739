h1, h2, h3, h4, h5, h6, p, span {
    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.block {
    display: block
}

.flex {
    display: flex
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.center {
    justify-content: center;
}

.bold {
    font-weight: bold;
}