@media (min-width: 768px) {
    .title {
        font-size: 6rem;
    }
    
}

@media (min-width: 576px) {
    .title {
        font-size: 4.5rem;
    }
    
}

@media (min-width: 992px) {
    .title {
        font-size: 6.5rem;
    }
    
}

@media (min-width: 1200px) {
    .title {
        font-size: 7.5rem;
    }
    
}

@media (min-width: 1400px) {
    .title {
        font-size: 8rem;
    }
    
}

@media (max-width: 576px) {
    .title {
        font-size: 2.25rem;
    }
    
}