.about-me-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.about-me-title {
    margin-bottom: 1.4rem;
}

@media (max-width: 1200px) {
    .about-me-container {
        padding: 2.5rem;
    }
}