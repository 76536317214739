@media (min-width: 768px) {
    .section-title {
        font-size: 6rem;
    }
    
}

@media (min-width: 576px) {
    .section-title {
        font-size: 3rem;
    }
    
}

@media (min-width: 992px) {
    .section-title {
        font-size: 3rem;
    }
    
}

@media (min-width: 1200px) {
    .section-title {
        font-size: 3.25rem;
    }
    
}

@media (min-width: 1400px) {
    .section-title {
        font-size: 3.75rem;
    }
    
}

@media (max-width: 576px) {
    .section-title {
        font-size: 2.25rem;
        min-height: 80px;
        min-width: 250px;
    }
    
}
