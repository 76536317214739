.glucose-list {
    list-style-type: none;
    padding: 0;
}

.glucose-list h1 {
    font-size: 5rem;
    text-align: center;
}

.glucose-list h2 {
    text-align: center;
    font-size: 3rem;
}

.center-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}